import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import Box from "@mui/material/Box";
import AmsPage from "./AmsPage";
import CsrSaveHistory from "./CsrSaveHistory";
import JobsReviewedList from './JobsReviewedList';
import CsrPendingReport from "./CsrPendingReport";
import CsrReadyJobs from "./CsrReadyJobs";

const CsrReport = (props) => {
  const [msgVisible, setMsgVisible] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [activeTab, setActiveTab] = useState("table1");
  const [roles, setRoles] = useState(sessionStorage.getItem("roles"));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {roles.includes('CsrReport')? (
         <div>
         <div style={{ width: "100%" }}>
           <div className="app-container">
             <div style={{ padding: "5px", width: "95%" }}>
               {msgVisible && (
                 <div className="alert-container">
                   <div className={msgClass}>{msgText}</div>
                 </div>
               )}
               <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                 <Tabs
                   value={activeTab}
                   onChange={handleTabChange}
                   variant="scrollable"
                   scrollButtons
                   allowScrollButtonsMobile
                 >
                   <Tab
                     label="AMS Report"
                     value="table1"
                     style={{ backgroundColor: "white !important" }}
                   />
                     <Tab
                     label="CSR Save History"
                     value="table2"
                     style={{ backgroundColor: "white !important" }}
                   /> 
                    <Tab
                     label="Csr Ready Jobs"
                     value="table3"
                     style={{ backgroundColor: "white !important" }}
                   />
                   <Tab
                     label="Jobs Reviewed"
                     value="table4"
                     style={{ backgroundColor: "white !important" }}
                   />
                  <Tab
                     label="CSR Export History"
                     value="table5"
                     style={{ backgroundColor: "white !important" }}
                   />
                 </Tabs>
               </Box>
               <div>
                 {activeTab == "table1" && <AmsPage activeTab={activeTab} />}
                 {activeTab == "table2" && <CsrSaveHistory />}
                 {activeTab == "table3" && <CsrReadyJobs />}
                 {activeTab == "table4" && <JobsReviewedList />}
                 {activeTab == "table5" && <CsrPendingReport />}
               </div>
             </div>
           </div>
         </div>
       </div>
      ) :''}
    </>
  );
};

export default CsrReport;
