import React from "react";
import { Route, Routes } from "react-router-dom";
import App from "../App";
import Home from "../Components/Home";
import { Dashboard } from "../Components/DashBoard";
import { LandingPage } from "../Components/LandingPage";
import { baseData } from "../Services/Constants";
import AllJob from "../Components/AllJob";
import UnAuthorizedUser from "../Components/UnAuthorizedUser";
import DispatchedJobScreen from "../Components/DispatchedJobScreen";
import Report from "../Components/Report";
import ChecklistErrorLog from "../Components/ChecklistErrorLog";
import CsrReport from "../Components/CsrReport";
import DeletionReport from "../Components/DeletionReport";

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<LandingPage />} /> */}
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/job" element={<Home />} />
      <Route path="/AllJob" element={<AllJob />} />
      {sessionStorage.getItem("role") && sessionStorage.getItem("role").includes('report') && (
  <Route path="/AdminAccess" element={<Report />} />
)}
{sessionStorage.getItem("roles") && sessionStorage.getItem("roles").includes('CsrReport') && (
  <Route path="/CSRReportPanel" element={<CsrReport />} />
)}
{sessionStorage.getItem("role") && sessionStorage.getItem("role").includes('DeletionReport') && (
  <Route path="/DeletionReportPanel" element={<DeletionReport />} />
)}

      <Route path="/UnAuthorizedUser" element={<UnAuthorizedUser />} />
      <Route path="/DispatchedJob" element={<DispatchedJobScreen />} />

      <Route path="/xlpage" element={<App baseData={baseData} />} />
      <Route path="/csrView" element={<App baseData={baseData} />} />
      <Route path="/checkListError" element={<ChecklistErrorLog />} />
    </Routes>
  );
};

export default AppRoutes;
