import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField, PrimaryButton } from "@fluentui/react";
import {
  processAndUpdateToken,
  formatTo12HourIST,
} from "../Services/CommonFunctions";
import { baseUrl } from "../Services/Constants";
import CommonDataTable from "./CommonDataTable";
import { ReportExcelExport } from "./ExportExcel/ReportExcelExport";

const JobsReviewedList = () => {
  const [data, SetData] = useState([]);
  const [jobInstanceFiltered, setJobInstanceFiltered] = useState([]);
  const [isSearch, setIsSearch] = React.useState(false);
  const [searchText, setSearchText] = useState("");
  const [alignment, setAlignment] = useState("JobReviwedList");

  const column = [
    {
      name: "Id",
      selector: (row) => row.Id,
      sortable: true,
      style: { fontSize: "11px !important" },
      width: "200px",
    },
    {
      name: "Job Id",
      selector: (row) => row?.Jobid,
      style: { fontSize: "11px !important" },
    },
    {
      name: "user",
      selector: (row) => row?.User,
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "CreatedOn",
      selector: (row) => row.CreatedOn,
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "UpdatedOn",
      selector: (row) => row.UpdatedOn,
      sortable: true,
      style: { fontSize: "11px !important" },
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let token = sessionStorage.getItem("token");
    document.body.classList.add("loading-indicator");
    token = await processAndUpdateToken(token);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        baseUrl + "/api/Defaultdatum/GetJobReviewedRecords",
        {
          headers,
        }
      );
      document.body.classList.remove("loading-indicator");
      if (response.status !== 200) {
        return "failed";
      }
      if (response?.data) {
        response.data = response.data.map((e) => {
          e["CreatedOn"] = formatTo12HourIST(e["CreatedOn"]);
          e["UpdatedOn"] = formatTo12HourIST(e["UpdatedOn"]);
          return e;
        });
        SetData(response.data);
        setJobInstanceFiltered([]);
        setIsSearch(false);
      } else {
        return "failed";
      }
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      return "failed";
    }
  };

  const filterData = () => {
    if (searchText && searchText?.length > 0) {
      const filteredData = data?.filter(
        (f) =>
          f?.Jobid?.toLowerCase()?.trim()?.includes(searchText?.trim()) ||
          f?.User?.toLowerCase()?.trim()?.includes(searchText?.trim())
      );
      setJobInstanceFiltered(filteredData);
      setIsSearch(true);
    } else {
      setJobInstanceFiltered([]);
      setIsSearch(false);
    }
  };

  const exportData = () => {
    if (!isSearch && data && data?.length > 0) {
      const dataForExport = [{ data }];
      const fileName = alignment;
      ReportExcelExport(dataForExport, fileName);
    } else if (
      isSearch &&
      jobInstanceFiltered &&
      jobInstanceFiltered?.length > 0
    ) {
      const dataForExport = [{ data: jobInstanceFiltered }];
      const fileName = alignment;
      ReportExcelExport(dataForExport, fileName);
    }
  };

  return (
    <div style={{ width: "100%", paddingTop: "5px", marginLeft: "5px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "start" }}>
          <TextField
            placeholder="Search JobId / user ..."
            value={searchText}
            onChange={(e, newValue) => {
              if (!newValue) {
                setIsSearch(false);
              }
              setSearchText(newValue);
            }}
            style={{
              width: "250px",
              marginRight: "8px",
            }}
          />
          <PrimaryButton
            text={
              <span style={{ color: "white", fontWeight: "bold" }}>Search</span>
            }
            onClick={() => {
              filterData();
            }}
            style={{
              marginLeft: "-50px",
              height: "32.1px",
              fontSize: "10px",
            }}
          />
          <PrimaryButton
            text={
              <span style={{ color: "white", fontWeight: "bold" }}>
                Refresh
              </span>
            }
            onClick={() => {
              getData();
            }}
            style={{
              marginLeft: "20px",
              height: "32.1px",
              fontSize: "10px",
            }}
          />
        </div>
        <div>
          <PrimaryButton
            text={
              <span style={{ color: "white", fontWeight: "bold" }}>Export</span>
            }
            onClick={() => {
              exportData();
            }}
            disabled={!(data?.length > 0 || jobInstanceFiltered?.length > 0)}
            style={{
              height: "32.1px",
              fontSize: "10px",
            }}
          />
        </div>
      </div>
      <br />
      <div>
        <CommonDataTable
          key={"jData"}
          column={column}
          data={isSearch ? jobInstanceFiltered : data}
        />
      </div>
    </div>
  );
};
export default JobsReviewedList;
