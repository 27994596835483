import { Icon, Stack } from "@fluentui/react";
import { IconButton } from "@fluentui/react/lib/Button";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { initializeIcons } from "office-ui-fabric-react";
import { Link } from "react-router-dom";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";


initializeIcons();

export const AppSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [canReportVisible, setCanReportVisible] = useState(false);
  const [canAdminReportVisible, setCanAdminReportVisible] = useState(false);
  const [canDeletionReportVisible, setCanDeletionReportVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [msgVisible, setMsgVisible] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const location = useLocation();
  const showSidebar = location.pathname !== '/csrView';


  useEffect(() => {
    async function fetchData() {
      const url = new URL(window.location.href);
      const userName = sessionStorage.getItem("userName") || url?.searchParams.get("user");
      await GetAllRoles(userName);
    }
    fetchData();
    setSelectedOption(location.pathname.replace("/", ""));
  }, [location]);

  // 

  const GetAllRoles = async (UserName) => {
    if (UserName) {
      document.body.classList.add("loading-indicator");
      var token = sessionStorage.getItem("token");
      const updatedToken = await processAndUpdateToken(token);
      const headers = {
        Authorization: `Bearer ${updatedToken}`,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get(
          baseUrl + "/api/Defaultdatum/GetUserRole?userName=" + `${UserName}`,
          { headers }
        );
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

const Role = response?.data?.Role == null || undefined ? [] : response?.data?.Role;
const Roles = response?.data?.Roles == null || undefined ? [] : response?.data?.Roles;


        if (Role.includes('report') ) {
          setCanAdminReportVisible(true)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        } else {
          setCanAdminReportVisible(false)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        }
        if (Roles.includes('CsrReport') ) {
          setCanReportVisible(true)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        } else {
          setCanReportVisible(false)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        }
        if (Role.includes('DeletionReport') ) {
          setCanDeletionReportVisible(true)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        } else {
          setCanDeletionReportVisible(false)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        }
      } catch (error) {
        setMsgVisible(true);
        setMsgClass("alert error");
        setMsgText(`Error fetching data: ${error}`);
        setTimeout(() => {
          setMsgVisible(false);
          setMsgText("");
        }, 3500);
      } finally {
        document.body.classList.remove("loading-indicator");
      }
    }
  };

  const handleMouseEnter = () => {
    setIsSidebarOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSidebarOpen(false);
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderSidebarButton = (item) => {
    const isSelected = location.pathname === `/${item.key}`;
    let backgroundColor = isSelected ? "dimgrey" : "transparent";

    if (item.key === "job" && location.pathname === "/xlpage") {
      backgroundColor = "dimgrey";
    }

    return (
      <Link to={`/${item.key}`} key={item.key}>
        <Stack horizontal
          className="sidebar_item"
          style={{ backgroundColor }}
          onClick={() => {
            if (isSelected) {
              setSelectedOption(null);
            } else {
              setSelectedOption(item.key);
            }
          }}
        >
          <Stack.Item>
            <Icon
              iconName={item.iconProps.iconName}
              style={{ display: 'flex', fontSize: '20px', margin: '5px 3px 5px 3px' }}
            />
          </Stack.Item>
          {isSidebarOpen && (
            <Stack.Item>
              <span style={{ display: 'flex', fontSize: '14px', margin: '6px 2px 6px 2px' }}>{item.name}</span>
            </Stack.Item>
          )}
        </Stack>
      </Link>
    );
  };

  return showSidebar ? (
    <div
      className={`sidebar-container ${isSidebarOpen ? "open" : "collapsible"}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`sidebar_header p`} style={{ fontFamily: "Montserrat, sans-serif" }}>
        <IconButton
          iconProps={{ iconName: 'CollapseMenu' }}
          title="Toggle Sidebar"
          ariaLabel="Toggle Sidebar"
          onClick={handleToggleSidebar}
          styles={{
            root: {
              background: 'transparent',
              width: 'auto',
              selectors: { '&:hover': { background: 'dimgrey' }, },
            },
            icon: {
              color: 'black',
              height: '15.8px',
              margin: '0px 4px 0px 5px',
              fontSize: '20px',
              selectors: {
                '&:hover': {
                  color: 'black',
                },
              },
            },
          }}
        />
        {renderSidebarButton({
          key: "dashboard",
          name: "Home",
          iconProps: { iconName: "Home" },
          isVisible: true,
        })}
        {renderSidebarButton({
          key: "job",
          name: "My Jobs",
          iconProps: { iconName: "FabricUserFolder" },
          isVisible: true,
        })}
        {renderSidebarButton({
          key: "AllJob",
          name: "All Jobs",
          iconProps: { iconName: "Group" },
          isVisible: true,
        })}
        {canAdminReportVisible && renderSidebarButton({
          key: "AdminAccess",
          name: "Admin Access",
          iconProps: { iconName: "TextDocument" },
          isVisible: true,
        })}
         {canReportVisible && renderSidebarButton({
          key: "CSRReportPanel",
          name: "CSR Report",
          iconProps: { iconName: "TextDocument" },
          isVisible: true,
        })}
         {canDeletionReportVisible && renderSidebarButton({
          key: "DeletionReportPanel",
          name: "Deletion Report Panel",
          iconProps: { iconName: "TextDocument" },
          isVisible: true,
        })}
        {renderSidebarButton({
          key: "checkListError",
          name: "CheckList Error",
          iconProps: { iconName: "ReleaseGateError" },
          isVisible: true,
        })}
      </div>
    </div>
  ) : null;
};
